import React from 'react'
import logo from './assets/icons/Cookie-1.svg'
import './App.css'
import {Colors} from "./theme/Colors";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2 style={styles.message}>Sex and cookies</h2>
      </header>
    </div>
  );
}

export default App;

const styles = {
  message: {
    color: Colors.dark,
    letterSpacing: 10,
    textTransform: 'uppercase',
    fontWeight: 800
  }
}
